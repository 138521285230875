<template>
  <div class="report-con">
    <div class="report-company">{{ enterpriseName }}</div>
    <image-loader style="margin:0 auto" :src="headerPic" :width="1000" :height="68"/>
    <div class="report-main">
      <div class="report-top-btn">评测结果</div>
      <div class="report-main-title">
        <div class="r-points">{{ totalScore }}<span>分</span></div>
        <div class="r-title">
          企业创新能力得分
          <div class="r-time">评测时间：{{ updatedTime }}</div>
        </div>
        <div class="r-intr">
          高新技术企业认定过程将对自主知识产权、研究开发组织管理水平、科技成果转化能力、以及资产与销售额成⻓ 性指标进行具体评定。在评定过程中分为知识产权、科技成果转化能力、研究开发组织管理水平、企业成⻓性等四项
          指标，用于评价企业利用科技资源进行创新、经营创新和取得创新成果等方面的情况。各级指标均按整数打分，满分 为100分，综合得分达到70分以上(不含70分)为符合认定要求。
        </div>
      </div>
      <div class="report-main-table">
        <table>
          <colgroup>
            <col name="column_1" width="219">
            <col name="column_2" width="560">
            <col name="column_3" width="131">
            <col name="column_4" width="173">
          </colgroup>
          <thead style="line-height: 188px">
          <tr>
            <th>指标项</th>
            <th>评分项</th>
            <th>得分</th>
            <th>合计</th>
          </tr>
          </thead>
          <template v-for="group in itemList">
            <tr v-for="(item, index) in group.list" :key="item.id">
              <td style="background: #1C345D;color: #9CE3FF" v-if="index === 0" :rowspan="group.list.length"
                  v-html="group.name"></td>
              <td style="font-size: 22px">{{ item.itemCondition }}</td>
              <td style="color: #FFBF00;font-size: 22px">{{ item.countryEval }}分</td>
              <td style="color: #FFBF00;font-size: 26px" v-if="index === 0" :rowspan="group.list.length">
                {{ group.list.reduce((c, p) => (~~c + ~~p.countryEval), 0) }}分
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="report-des">
        <div class="report-des-info">
          <div class="r-d-1">具体测评结果，仅供企业参考，如对测试结果有疑问，可直接在线咨询或拨打客服热线咨询!</div>
          <div class="r-d-2">
            若您需要高企认定过程中的具体专业指导建议(含如何提升创新能力评价得分)，欢迎联系我们了解高企技术、企业认定服务整体解决方案。各级指标均按整数打分，满分为100分，综合得分达到70分以上(不含70分)为符合认定要求。
          </div>
        </div>
        <div class="report-op dp-flex flex-between">
          <div class="report-cont-btn" @click="openZoosUrl">联系客服</div>
          <div class="report-cont-info dp-flex flex-align-center">
            <img style="width:24px" src="@/assets/image/icon-phone.png"/>
            <div>客服电话：</div>
            <span>0519-85581916</span>
          </div>
        </div>
      </div>
      <div class="report-im">
        <div class="im-title">贵司与国家高新技术企业认定硬性要求对照详情如下</div>
        <div class="im-item" v-for="(item, index) in baseList" :key="item.id">
          <div class="im-item-title">{{ index + 1 }}、{{ item.itemCondition }}</div>
          <template v-if="index === 4">
            <div class="im-item-tb" style="background: #1C345D;">
              <div style="color: #9CE3FF !important">科技人员占企业职工总数比例（%）</div>
              <p style="color: #9CE3FF !important">结果</p>
            </div>
            <div class="im-item-tb">
              <div style="color: #FFBF00;background: none;">{{ item.countryLevel }}</div>
              <p>{{ item.countryEval === "Y" ? "符合" : "不符合" }}</p>
            </div>
            <div class="im-item-foot">{{ item.footTxt }}</div>
          </template>
          <template v-else-if="index === 3">
            <div class="im-item-tb" style="background: #1C345D;">
              <div style="color: #9CE3FF;width: 400px">I类 知识产权</div>
              <div style="color: #9CE3FF;width: 400px">II类 知识产权</div>
              <p style="color: #9CE3FF">结果</p>
            </div>
            <div class="im-item-tb">
              <div style="color:#FFBF00;width: 400px;background: none;">{{ item.countryLevel.match(/\d+/g)[0] }}</div>
              <div style="color:#FFBF00;width: 400px;background: none;">
                {{ item.countryLevel.match(/\d+/g)[1].toString() }}
              </div>
              <p>{{ item.countryEval === "Y" ? "符合" : "不符合" }}</p>
            </div>
            <div class="im-item-foot">{{ item.footTxt }}</div>
          </template>
          <template v-else>
            <div class="im-item-tb">
              <div>我的答案：<span>{{ item.countryLevel }}</span></div>
              <p>{{ item.countryEval === "Y" ? "符合" : "不符合" }}</p>
            </div>
            <div class="im-item-foot">{{ item.footTxt }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="report-test-btn" @click="reTest">重新测评</div>
  </div>
</template>

<script>
import {getReport} from "@/plugins/api/EnterpriseExam";

export default {
  name: "ReportView",
  data() {
    return {
      headerPic: require('@/assets/image/report-title.png'),
      baseList: [],
      itemList: [],
      totalScore: 0,
      enterpriseName: '',
      updatedTime: '',
      baseMap: {
        0: 0,
        1: 1,
        2: 3,
        3: 4,
        4: 2
      },
      itemMap: {
        0: {index: 0, groupName: '知识产权'},
        1: {index: 1, groupName: '知识产权'},
        2: {index: 2, groupName: '知识产权'},
        3: {index: 3, groupName: '知识产权'},
        4: {index: 5, groupName: '科技成果 <br/> 转化情况'},
        5: {index: 4, groupName: '研究研发<br/>组织管理能力'},
        6: {index: 8, groupName: '研究研发<br/>组织管理能力'},
        7: {index: 9, groupName: '研究研发<br/>组织管理能力'},
        8: {index: 11, groupName: '研究研发<br/>组织管理能力'},
        9: {index: 10, groupName: '研究研发<br/>组织管理能力'},
        10: {index: 6, groupName: '企业成长力'},
        11: {index: 7, groupName: '企业成长力'}
      },
      baseFootTxt: [
        '高新企业要求:企业申请认定时须注册成立一年以上。',
        '高新企业要求:企业主要产品(服务)发挥核心支持作用的技术需属于《国家重点支持的高新技术领域》规定的范围)。',
        '高新企业要求:企业合法经营，确保生产过程安全环保，生产产品质量过关，是企业健康发展的基础，也是企业自身所需要承担的基本社会责任。',
        '高新企业要求:企业申请认定时I类知识产权与II类知识产权需符合要求。',
        '企业申请认定时，企业从事研发和相关技术创新活动的科技人员占企业当年职工总数的比例需不低于10%。'
      ]
    };
  },
  created() {
    console.log(this.$route.query.id)
    this.getReportFun()
  },
  methods: {
    async getReportFun() {
      let data = {
        evalId: this.$route.query.id || '0',
      };
      let json = await getReport(data);
      if (json.code == "200") {
        let time = json.data.updatedTime ? json.data.updatedTime : json.data.createTime;
        this.updatedTime = time;
        this.totalScore = json.data.totalScore;
        console.log(this.totalScore)
        this.enterpriseName = json.data.enterpriseName;
        this.baseList = this.sortArrWithMap(json.data.baseList, this.baseMap);
        this.baseList = this.baseList.map((item, index) => {
          return Object.assign({footTxt: this.baseFootTxt[index]}, item);
        })
        let itemList = json.data.itemList;
        let groups = [];
        itemList.forEach((item, index) => {
          let realItem = itemList[this.itemMap[index].index];
          let groupName = this.itemMap[index].groupName;
          if (groups.some(x => x.name === groupName)) {
            groups.filter(y => y.name === groupName)[0].list.push(realItem);
          } else {
            groups.push({name: groupName, list: [realItem]});
          }
        });
        this.itemList = groups;
      }
    },
    sortArrWithMap(arr, map) {
      let result = [];
      arr.forEach((item, index) => {
        result.push(arr[map[index]])
      });
      return result;
    },
    reTest() {
      this.$router.push({
        path: '/enterprise/enterprise-physical-examination',
        query: {
          page: 'Measurement',
          evalId: this.$route.query.id || '0'
        }
      })
    },
    openZoosUrl() {
      openZoosUrl("chatwin");
    }
  }
};
</script>

<style scoped lang="scss">
.report-con {
  background: url("~@/assets/image/report-bg.png");
  background-size: cover;
  width: 100%;
  height: auto !important;
  padding-top: 177px;
  padding-bottom: 50px;
}

.report-company {
  font-size: 38px;
  color: #AADDFC;
  width: 532px;
  margin: 0 auto 54px auto;
}

.report-main {
  width: 1290px;
  margin: 0 auto;
  margin-top: 96px;
  background: url("~@/assets/image/report-main-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 62px 105px 100px 105px;
  position: relative;
}

.report-top-btn {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 390px;
  height: 78px;
  line-height: 78px;
  color: #FFBF00;
  text-align: center;
  font-size: 44px;
  background: url("~@/assets/image/report-top-btn.png");
  background-size: 100% 100%;
}

.report-main-title {
  .r-title {
    font-size: 32px;
    color: #fff;
    text-align: center;
    position: relative;

    .r-time {
      font-size: 16px;
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  .r-points {
    text-align: right;
    font-size: 72px;
    color: #FFBF00;

    span {
      font-size: 40px;
    }
  }

  .r-intr {
    font-size: 20px;
    color: #fff;
    margin-top: 50px;
  }
}

.report-main-table {
  margin-top: 40px;

  table, table tr th, table tr td {
    border: 1px solid #00A2FB;
    text-align: center;
    font-size: 24px;
  }

  table thead {
    background: #184894;
    color: #9CE3FF;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    td {
      color: #5AB2F8;
      padding: 20px 24px;
    }
  }
}

.report-des-info {
  font-size: 22px;
  margin: 40px 0;

  .r-d-1 {
    color: #9CE3FF;
    text-indent: 40px;
  }

  .r-d-2 {
    color: #5AB2F8;
  }
}

.report-cont-btn {
  background: #174793;
  border-radius: 2px;
  border: 1px solid #247FFF;
  width: 200px;
  line-height: 60px;
  font-size: 24px;
  text-align: center;
  color: #9CE3FF;
  cursor: pointer;
}

.report-cont-info {
  div {
    font-size: 22px;
    color: #9CE3FF;
    margin: 0 10px;
  }

  span {
    font-size: 40px;
    color: #FFBF00;
  }
}

.report-im {
  margin-top: 60px;

  > div {
    margin-bottom: 30px;
  }

  .im-title {
    font-size: 32px;
    color: #FFFFFF;
  }

  .im-item-title {
    font-size: 24px;
    color: #9CE3FF;
    margin-bottom: 24px;
  }

  .im-item-tb {
    border: 1px solid #00A2FB;
    line-height: 84px;
    text-align: center;
    display: flex;

    > div {
      font-size: 24px;
      width: 737px;
      border-right: 1px solid #00A2FB;
      background: #1C345D;
      color: #9CE3FF;

      span {
        color: #5BD171;
      }
    }

    > p {
      font-size: 26px;
      color: #FFBF00;
      flex-grow: 1;
    }
  }

  .im-item-foot {
    font-size: 20px;
    color: #5AB2F8;
    margin-top: 24px;
  }
}

.report-test-btn {
  margin: 60px auto;
  width: 448px;
  height: 138px;
  line-height: 138px;
  color: #9CE3FF;
  text-align: center;
  font-size: 44px;
  background: url("~@/assets/image/report-test-btn.png");
  background-size: 100% 100%;
  cursor: pointer;
}
</style>
